import React, { useContext } from 'react'; //eslint-disable-line no-unused-vars
import { userContext } from '../contexts/user';

const MessageDateTime = React.forwardRef(({ date }, ref) => {
    const user = useContext(userContext);

    var displayDateTime = null;
    if (typeof date !== 'undefined') {
        var dateTime = new Date(date);
        var dt = '';
        var d = dateTime.getDate();
        var mo = dateTime.getMonth() + 1;
        var h = dateTime.getHours();
        var m = dateTime.getMinutes();
        var s = dateTime.getSeconds();

        /*if (d < 10) dt += '0'; dt += d + '.';
        if (mo < 10) dt += '0'; dt += mo + '.';

        dt += dateTime.getFullYear();

        displayDateTime = dt + ' - ';*/

        if (h < 10) dt += '0'; dt += h + ':';
        if (m < 10) dt += '0'; dt += m + ':';
        if (s < 10) dt += '0'; dt += s;

        displayDateTime = dt;
    }
    
    if (displayDateTime === null)
        return (null);
  
    return (
        <div ref={ref} class="vfwmessenger__msgdt">
            {displayDateTime}
        </div>
    );
})

export default MessageDateTime;