import React, { useContext } from 'react'; //eslint-disable-line no-unused-vars
import { userContext } from '../contexts/user';

const MessagesDate = React.forwardRef(({ date }, ref) => {
    const user = useContext(userContext);

    var displayDate = null;
    if (typeof date !== 'undefined') {
        var dateTime = new Date(date);
        var dt = '';
        var d = dateTime.getDate();
        var mo = dateTime.getMonth() + 1;

        if (d < 10) dt += '0'; dt += d + '.';
        if (mo < 10) dt += '0'; dt += mo + '.';

        dt += dateTime.getFullYear();
        
        if (dt != window.lastMsgDate) {
            displayDate = dt;
            window.lastMsgDate = displayDate;
        }
    }
    
    if (displayDate === null)
        return (null);
  
    return (
        <p ref={ref} class="vfwmessenger__dt">
            {displayDate}
        </p>
    );
})

export default MessagesDate;