import {gql, useQuery} from '@apollo/client';
import { useEffect, useRef, useState } from 'react';
import ScrollableFeed from 'react-scrollable-feed';

import Message from './Message';
import MessagesDate from './MessagesDate';
import NotFound from '../pages/NotFound';
import Spinner from './Spinner';


const GET_ROOMMESSAGES = gql`
  query GetRoomMessages($room: Int, $cursor: Int) {
    roomMessages(roomId: $room, cursor: $cursor){
      id
      message
      createdAt
      user {
        id
        nickname
        chatrooms {
          role
        }
      }
      deleted
    }
  }
`;

const MSG_SUB = gql`
  subscription MsgSub {
    messageAdded{
      id
      message
      roomId
      createdAt
      user {
        id
        nickname
        chatrooms {
          role
        }
      }
      deleted
    }
  }
`;


const Messages = ({roomId}) => {
  const [cursor, setCursor] = useState();
  const [showSpinner, setShowSpinner] = useState(true);
  const {error, loading, data, subscribeToMore, fetchMore, networkStatus} = useQuery(GET_ROOMMESSAGES, {notifyOnNetworkStatusChange: true, variables: {room: roomId, cursor: null}});
  const [shouldRefetch, setShouldRefetch] = useState(true);

  let scrollableRootRef = useRef(null);
  const lastScrollDistanceToBottomRef = useRef(0);
  const [shouldScroll, setShouldScroll] = useState(false);

  useEffect(() => {
    const scrollableRoot = scrollableRootRef.current;
    const lastScrollDistanceToBottom = lastScrollDistanceToBottomRef.current ?? 0;
    if(scrollableRoot && shouldScroll){
      const delta = scrollableRoot.scrollHeight - lastScrollDistanceToBottom;
      scrollableRoot.scrollBy({top: delta, behavior: 'smooth'})
      setShouldScroll(false);
    }
  }, [data])//eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setCursor(Number(data?.roomMessages[0]?.id));
  }, [data])

  useEffect(() => {
    subscribeToMore({
      document: MSG_SUB,
      variables: {
        roomId: roomId
      },
      updateQuery: (prev, {subscriptionData}) => {
        if (!subscriptionData?.data) return prev;
        if (subscriptionData?.data?.messageAdded?.deleted) return prev;
        if (subscriptionData?.data?.messageAdded?.roomId != roomId) return prev;
        const newMsg = subscriptionData?.data?.messageAdded;
        return {
          roomMessages: [...prev.roomMessages, newMsg]
        }
      }
    });
  },[subscribeToMore])

  const handleOnScroll = (e) => {
    const scrollableRoot = scrollableRootRef.current;
    if(scrollableRoot){
      const scrollDistanceToBottom = scrollableRoot.scrollHeight - scrollableRoot.scrollTop;
      lastScrollDistanceToBottomRef.current = scrollDistanceToBottom;
    }

    if(e?.isAtTop){
      setShouldScroll(true);
      shouldRefetch && fetchMore({variables: {room: roomId, cursor}}).then(d => {
        if(d?.data?.roomMessages?.length === 0 || d?.data?.roomMessages?.length < 15){ //every fetch fetches 15 messages
          setShouldRefetch(false);
        }
      });
    }
  }

  const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  useEffect(() => {
    const sleepSpinner = async () => {
      await sleep(500);
      setShowSpinner(false);
    }
    sleepSpinner();
  });

  //callback for ScrollableFeed, gives access to the wrapperRef, needed for calculations for the scrollposition
  const handleOnMount = (e) => {
    scrollableRootRef = e;
  }

  // if(error) return <p style={{color: 'crimson'}}>Fehler: {error.message}</p>

  window.lastMsgDate = null;
  if(!loading && error) return <NotFound />;
  return(
    <div className="vfwmessenger__msg__container">
      {
        (loading && !networkStatus===3) || showSpinner ?
          <Spinner type="triangle" color="crimson" />
        :
        <ScrollableFeed onMount={handleOnMount} onScroll={handleOnScroll}>
          {data?.roomMessages?.map((message) => (
            <>
              <MessagesDate date={message?.createdAt} />
              <Message key={message.id} message={message} roomId={roomId} />
            </>
          ))}
        </ScrollableFeed>
      }
    </div>
  )
}

export default Messages;